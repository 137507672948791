
             
.fude-table {
  display: flex!important;
  flex-direction: column;
  margin: 0 auto;
}

.fude-table__collapsed {
  display: none!important;
}

.fude-table table.v-table thead td:not(:nth-child(1)),
.fude-table table.v-table tbody td:not(:nth-child(1)),
.fude-table table.v-table tfoot td:not(:nth-child(1)),
.fude-table table.v-table thead th:not(:nth-child(1)),
.fude-table table.v-table tbody th:not(:nth-child(1)),
.fude-table table.v-table tfoot th:not(:nth-child(1)),
.fude-table table.v-table thead td:first-child,
.fude-table table.v-table tbody td:first-child,
.fude-table table.v-table tfoot td:first-child,
.fude-table table.v-table thead th:first-child,
.fude-table table.v-table tbody th:first-child,
.fude-table table.v-table tfoot th:first-child{
  padding: 0 8px;
}
.fude-table table.v-table thead td:first-child,
.fude-table table.v-table tbody td:first-child,
.fude-table table.v-table tfoot td:first-child,
.fude-table table.v-table thead th:first-child,
.fude-table table.v-table tbody th:first-child,
.fude-table table.v-table tfoot th:first-child{
  padding-left: 24px;
}
.fude-table table.v-table thead td:last-child,
.fude-table table.v-table tbody td:last-child,
.fude-table table.v-table tfoot td:last-child,
.fude-table table.v-table thead th:last-child,
.fude-table table.v-table tbody th:last-child,
.fude-table table.v-table tfoot th:last-child {
  padding-right: 24px;
}

.fude-table table.v-table thead td,
.fude-table table.v-table thead th,
.fude-table table.v-table tfoot th {
  font-size: 15px;
  font-weight: 500;
}

.fude-table .fude-table-data {
  display: flex;
  align-self: center;
  width: 100%;
  
}

.fude-table .fude-table-data .fude-table-data-title {
  &:hover {
    cursor: pointer;
  }
}

.fude-table .action-btn {
  margin: 2px;
}

.fude-table .selected-row {
  background-color: #3F51B5;
}

.fude-table table.v-table thead tr,
.fude-table table.v-table tbody tr,
.fude-table table.v-table tfoot tr,
.fude-table table.v-table tbody td,
.fude-table table.v-table tbody th,
.fude-table table.v-table tfoot th,
.fude-table table.v-table tfoot td {
  height: 48px;
}

.fude-table.dense table.v-table thead tr,
.fude-table.dense table.v-table tbody tr,
.fude-table.dense table.v-table tfoot tr,
.fude-table.dense table.v-table tbody td,
.fude-table.dense table.v-table tbody th,
.fude-table.dense table.v-table tfoot td,
.fude-table.dense table.v-table tfoot th {
  height: 36px;
}
.fude-table.dense .v-toolbar__content {
  height: 38px!important;
}
.fude-table.dense .v-toolbar__content .v-toolbar__title {
  font-size: 16px;
}
.fude-table.dense .v-btn {
  margin: 4px;
}

.fude-table.dense-xs table.v-table thead tr,
.fude-table.dense-xs table.v-table tbody tr,
.fude-table.dense-xs table.v-table tfoot tr,
.fude-table.dense-xs table.v-table tbody td,
.fude-table.dense-xs table.v-table tbody th,
.fude-table.dense-xs table.v-table tfoot td,
.fude-table.dense-xs table.v-table tfoot th {
  height: 32px;
}
.fude-table.dense-xs .v-toolbar__content {
  height: 32px!important;
}
.fude-table.dense-xs .v-toolbar__content .v-toolbar__title {
  font-size: 16px;
}
.fude-table.dense-xs .v-btn {
  margin: 2px;
}

.fude-table .action-btns {
  display: flex;
  justify-content: flex-end;
}

.fude-table .action-btns .v-btn {
  margin-right: 0;
}

.fude-table .v-toolbar {
  margin-bottom: 0;
}

.fude-table .hide-brn {
  visibility: hidden;
}

.fude-table .v-datatable__progress {
  height: 3px!important;
}

.fude-table.fude-table--full_height {
  height: 100%;
  .fude-table-data {
    height: 100%;
  }
}
