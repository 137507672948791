.fude-dialog-card .v-card__title,
.fude-dialog-card .v-card__text {
  padding: 8px 16px !important;
}
.fude-dialog .fude-dialog-container,
.fude-dialog .fude-dialog-container > .layout {
  padding: 0;
}
.fude-dialog .fude-dialog-container {
  max-width: 100%!important;
}
.fude-dialog .fude-dialog-container.fude-dialog-container--wide {
  max-width: 100%!important;
}
.fude-dialog .v-card__text {
  padding: 8px !important;
  padding-bottom: 0!important;
}
.fude-dialog .v-card__actions {
  padding: 12px !important;
  padding-top: 8px!important;
}
.fude-dialog .v-card__actions--error {
  padding-top: 0!important;
  padding-bottom: 0!important;
}
.fude-dialog .fude-dialog-card.v-card.theme--dark {
  background-color: #303030;
}
.fude-dialog.auto-width {
  width: auto !important;
}
.fude-dialog.auto-width.xs {
  min-width: 220px;
}
.fude-dialog.auto-width.sm {
  min-width: 400px;
}
.fude-dialog.auto-width.md {
  min-width: 500px;
}
.fude-dialog.auto-width.lg {
  min-width: 500px;
}
.fude-dialog.auto-width.xl {
  min-width: 500px;
}
.fude-dialog.fude-dialog--actions-top .v-card__title {
  padding: 10px 8px 10px 16px;
}
.fude-dialog .v-card__actions .v-alert {
  width: 100%;
}
.fude-dialog.v-dialog--scrollable > .v-card > .v-card__title,
.fude-dialog.v-dialog--scrollable > .v-card > .v-card__actions {
  flex-grow: 0;
}
.fude-dialog.v-dialog--fullscreen .fude-dialog-card__text {
  height: 100%;
}
.fude-dialog.v-dialog--fullscreen .fude-dialog-card__text .fude-table {
  margin-bottom: 8px;
}
